import { CardTemplate, CardTemplateProperty } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * カタログマスタに関する機能を提供します.
 */
class CardTemplateService {
  /**
   * 初期状態の CardTemplate
   */
  public get defaultCardTemplate(): CardTemplate {
    return {
      guid: "",
      name: "",
      genre: "",
      templateImageUrl: "",
      isVisible: true,
      defaultMessage: null,
      createdAt: "",
      updatedAt: "",
      cardTemplateProperties: [],
    } as CardTemplate;
  }
  /**
   * 初期状態の CardTemplateProperty
   */
  public get defaultCardTemplateProperty(): CardTemplateProperty {
    return {
      guid: "",
      name: "",
      positionX: 0,
      positionY: 0,
      width: 0,
      height: 0,
      fontName: "",
      fontSize: 0,
      fontColor: "",
      bold: 0,
      align: null,
      createdAt: "",
      updatedAt: "",
      cardTemplate: this.defaultCardTemplate,
    } as CardTemplateProperty;
  }

  /**
   * すべてのメッセージカードテンプレートを取得して返します.
   * @returns すべてのメッセージカードテンプレート
   */
  public async allCardTemplates() {
    const response = await sdk.cardTemplates();
    if (response?.cardTemplates) {
      return response.cardTemplates as CardTemplate[];
    }
    return [] as CardTemplate[];
  }

  /**
   * 指定したGUIDに一致するメッセージカードテンプレートを取得して返します.
   * @param guid メッセージカードテンプレートのGUID
   * @returns メッセージカードテンプレート
   */
  public async getCardTemplate(guid: string) {
    const response = await sdk.cardTemplate({ cardTemplateGuid: guid });
    return response.cardTemplate as CardTemplate;
  }

  /**
   * メッセージカードテンプレートを登録します.
   * メッセージカードテンプレートプロパティは同時には登録されません.
   * @param input メッセージカードテンプレートの登録情報
   * @returns 登録完了後のメッセージカードテンプレート
   */
  public async createCardTemplate(input: CardTemplate) {
    try {
      //登録
      const response = await sdk.createCardTemplate({
        createCardTemplateInput: {
          name: input.name,
          genre: input.genre,
          isVisible: input.isVisible,
          templateImageUrl: input.templateImageUrl,
          defaultMessage: input.defaultMessage,
          // cardTemplateProperties: cardTemplateProperties,
        },
      });
      return response.createCardTemplate;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * メッセージカードテンプレートを更新します.
   * メッセージカードテンプレートプロパティは同時には登録・更新されません.
   * @param input メッセージカードテンプレートの更新情報
   * @returns 更新完了後のメッセージカードテンプレート
   */
  public async updateCardTemplate(input: CardTemplate) {
    let response = undefined;
    try {
      //更新
      response = await sdk.updateCardTemplate({
        updateCardTemplateInput: {
          guid: input.guid,
          name: input.name,
          genre: input.genre,
          isVisible: input.isVisible,
          templateImageUrl: input.templateImageUrl,
          defaultMessage: input.defaultMessage,
          // cardTemplateProperties: cardTemplateProperties,
        },
      });
      const updated = response.updateCardTemplate;
      return updated;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * メッセージカードテンプレートを削除します.
   * @param input メッセージカードテンプレートの削除情報
   * @returns 削除したメッセージカードテンプレート
   */
  public async deleteCardTemplate(input: CardTemplate) {
    try {
      const response = await sdk.deleteCardTemplate({
        deleteCardTemplateInput: {
          guid: input.guid,
        },
      });
      return response?.deleteCardTemplate;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * 指定したGUIDに一致するメッセージカードテンプレートプロパティを取得して返します.
   * @param guid メッセージカードテンプレートプロパティのGUID
   * @returns メッセージカードテンプレートプロパティ
   */
  public async getCardTemplateProperty(guid: string) {
    const response = await sdk.cardTemplateProperty({
      cardTemplatePropertyGuid: guid,
    });
    return response.cardTemplateProperty as CardTemplateProperty;
  }

  /**
   * メッセージカードテンプレートプロパティを登録します.
   * @param input メッセージカードテンプレートプロパティの登録情報
   * @returns 登録完了後のメッセージカードテンプレートプロパティ
   */
  public async createCardTemplateProperty(input: CardTemplateProperty) {
    console.log(input);
    if (!input.cardTemplate || !input.cardTemplate.guid ) {
      throw new Error("メッセージカードテンプレートGUIDが指定されていません。");
    }
    try {
      //登録
      const response = await sdk.createCardTemplateProperty({
        createCardTemplatePropertyInput: {
          cardTemplateGuid: input.cardTemplate.guid,
          name: input.name,
          align: input.align,
          bold: input.bold,
          fontColor: input.fontColor,
          fontName: input.fontName,
          fontSize: input.fontSize,
          width: input.width,
          height: input.height,
          positionX: input.positionX,
          positionY: input.positionY,
        },
      });
      return response.createCardTemplateProperty;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * メッセージカードテンプレートプロパティを更新します.
   * @param input メッセージカードテンプレートプロパティの更新情報
   * @returns 更新完了後のメッセージカードテンプレートプロパティ
   */
  public async updateCardTemplateProperty(input: CardTemplateProperty) {
    try {
      //更新
      const response = await sdk.updateCardTemplateProperty({
        updateCardTemplatePropertyInput: {
          guid: input.guid,
          name: input.name,
          align: input.align,
          bold: input.bold,
          fontColor: input.fontColor,
          fontName: input.fontName,
          fontSize: input.fontSize,
          width: input.width,
          height: input.height,
          positionX: input.positionX,
          positionY: input.positionY,
        },
      });
      return response.updateCardTemplateProperty;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * メッセージカードテンプレートプロパティを削除します.
   * @param input メッセージカードテンプレートプロパティの削除情報
   * @returns 削除したメッセージカードテンプレートプロパティ
   */
  public async deleteCardTemplateProperty(input: CardTemplateProperty) {
    try {
      const response = await sdk.deleteCardTemplateProperty({
        deleteCardTemplatePropertyInput: {
          guid: input.guid,
        },
      });
      return response?.deleteCardTemplateProperty;
    } catch (err) {
      console.error(err);
    }
  }
}

export default new CardTemplateService();
