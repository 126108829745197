
import { DeliveryCalendarDayType } from "@/graphql/client";
import { Component, Vue } from "vue-property-decorator";
import typeService from "@/service/typeService";

@Component
export default class MixinFormatter extends Vue {
  //---------------------------
  // methods
  //---------------------------

  /**
   * 日付種別のラベルを返します.
   */
  public getDeliveryCalendarDayTypeLabel(
    item: DeliveryCalendarDayType
  ): string {
    return typeService.getDeliveryCalendarDayTypeLabel(item);
  }
}
