
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, integer, numeric } from "vuelidate/lib/validators";

@Component
export default class MixinMessageCardTemplatePropertyEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //X座標
      positionX: { required, integer },
      //Y座標
      positionY: { required, integer },
      //幅
      width: { required, numeric },
      //高さ
      height: { required, numeric },
      //フォントサイズ
      fontSize: { required, numeric },
      //太さ
      bold: { required, numeric },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * X座標の入力エラーメッセージを返します.
   */
  get positionXErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.positionX?.$dirty) return errors;
    !this.$v.item.positionX?.required && errors.push("X座標を入力してください.");
    !this.$v.item.positionX?.integer && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * Y座標の入力エラーメッセージを返します.
   */
  get positionYErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.positionY?.$dirty) return errors;
    !this.$v.item.positionY?.required &&
      errors.push("Y座標を入力してください.");
    !this.$v.item.positionY?.integer && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * フォントサイズの入力エラーメッセージを返します.
   */
  get fontSizeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.fontSize?.$dirty) return errors;
    !this.$v.item.fontSize?.required &&
      errors.push("フォントサイズを入力してください.");
    !this.$v.item.fontSize?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 幅の入力エラーメッセージを返します.
   */
  get widthErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.width?.$dirty) return errors;
    !this.$v.item.width?.required && errors.push("幅を入力してください.");
    !this.$v.item.width?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 高さの入力エラーメッセージを返します.
   */
  get heightErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.height?.$dirty) return errors;
    !this.$v.item.height?.required && errors.push("高さを入力してください.");
    !this.$v.item.height?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 太さの入力エラーメッセージを返します.
   */
  get boldErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.bold?.$dirty) return errors;
    !this.$v.item.bold?.required && errors.push("太さを入力してください.");
    !this.$v.item.bold?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
}
