





































































































































































import { CardTemplate, CardTemplateProperty } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import cardTemplateService from "@/service/cardTemplateService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/message_card/MixinMessageCardTemplatePropertyEditDialogValidator.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class MessageCardTemplatePropertyEditDialog extends  Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: CardTemplateProperty = cardTemplateService.defaultCardTemplateProperty;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: CardTemplateProperty): Promise<void> {
    console.log(item);

    //表示データ取得
    this.item = item;
    if (item.guid) {
      const temp = await cardTemplateService.getCardTemplateProperty(item.guid);
      if (temp) {
        this.item = temp;
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: CardTemplateProperty): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        cardTemplateService
          .updateCardTemplateProperty(this.item)
          .then((result) => {
            this.notifyUpdateSuccess(result as CardTemplate);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("プレースホルダの更新ができませんでした.");
          });
      } else {
        //create
        cardTemplateService
          .createCardTemplateProperty(this.item)
          .then((result) => {
            this.notifyCreateSuccess(result as CardTemplate);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("プレースホルダの登録ができませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: CardTemplate): CardTemplate {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: CardTemplate): CardTemplate {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
