import { DeliveryCalendarDayType } from "@/graphql/client";

/**
 * 各種種別に関する機能を提供します.
 */
class TypeService {

  //日付種別 ---------------------------------------------------
  private readonly DELIVERY_CALENDAR_DAY_TYPES = [
    DeliveryCalendarDayType.NonFlesh,
    DeliveryCalendarDayType.NonBusiness,
  ];

  /**
   * 日付種別をすべて返します.
   */
  public get deliveryCalendarDayTypes() {
    return this.DELIVERY_CALENDAR_DAY_TYPES;
  }

  /**
   * 指定した日付種別に対応するラベル（日本語名称）を返します.
   * @param type 日付種別
   * @returns 日付種別に対応するラベル（日本語名称）
   */
  public getDeliveryCalendarDayTypeLabel(
    type: DeliveryCalendarDayType
  ): string {
    switch (type) {
      case DeliveryCalendarDayType.NonFlesh:
        return "フレッシュお届け不可日";
      case DeliveryCalendarDayType.NonBusiness:
        return "非営業日";
      default:
        return type as string;
    }
  }
}

export default new TypeService();
