


























































































































































import { CardTemplate, CardTemplateProperty } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import cardTemplateService from "@/service/cardTemplateService";
import { required } from "vuelidate/lib/validators";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import ImagePicker from "@/components/molecules/ImagePicker.vue";
import MessageCardTemplatePropertyEditDialog from "@/components/organisms/message_card/MessageCardTemplatePropertyEditDialog.vue";
import MessageCardTemplatePropertyDeleteDialog from "@/components/organisms/message_card/MessageCardTemplatePropertyDeleteDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/message_card/MixinMessageCardEditDialogValidator.vue";
import { NotificationState } from "@/store/modules/notification";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
    ImagePicker,
    MessageCardTemplatePropertyEditDialog,
    MessageCardTemplatePropertyDeleteDialog,
  },
})
export default class MessageCardTemplateEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly editDialog!: MessageCardTemplatePropertyEditDialog;
  @Ref() readonly deleteDialog!: MessageCardTemplatePropertyDeleteDialog;
  @Ref() readonly imagePicker!: ImagePicker;
  @Ref() readonly itemForm!: HTMLFormElement;
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
    },
  };
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: CardTemplate = cardTemplateService.defaultCardTemplate;
  defaultCardTemplateProperty: CardTemplateProperty =
    cardTemplateService.defaultCardTemplateProperty;
  valid = false;
  propertyHeaders = [
    {
      text: "名称",
      value: "name",
      sortable: false,
    },
    {
      text: "X座標",
      value: "positionX",
      width: "8%",
      sortable: false,
    },
    {
      text: "Y座標",
      value: "positionY",
      width: "8%",
      sortable: false,
    },
    {
      text: "幅",
      value: "width",
      width: "8%",
      sortable: false,
    },
    {
      text: "高さ",
      value: "height",
      width: "8%",
      sortable: false,
    },
    {
      text: "フォント",
      value: "fontName",
      width: "13%",
      sortable: false,
    },
    {
      text: "サイズ",
      value: "fontSize",
      width: "8%",
      sortable: false,
    },
    {
      text: "カラー",
      value: "fontColor",
      width: "8%",
      sortable: false,
    },
    {
      text: "太さ",
      value: "bold",
      width: "8%",
      sortable: false,
    },
    {
      text: "文字寄せ",
      value: "align",
      width: "8%",
      sortable: false,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 表示中の場合は「表示中（ON）」、表示中でない場合は「表示中（OFF）」という文字列を返します.
   */
  get formattedIsVisible(): string {
    return this.item.isVisible ? "表示中（ON）" : "表示中（OFF）";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: CardTemplate): Promise<void> {

    //表示データ取得
    this.item = cardTemplateService.defaultCardTemplate;
    if (item.guid) {
      const temp = await cardTemplateService.getCardTemplate(item.guid);
      if (temp) {
        this.item = temp;
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: CardTemplate): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.imagePicker.reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        cardTemplateService
          .updateCardTemplate(this.item)
          .then((result) => {
            this.notifyUpdateSuccess(result as CardTemplate);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("カードテンプレートの更新ができませんでした.");
          });
      } else {
        //create
        cardTemplateService
          .createCardTemplate(this.item)
          .then((result) => {
            this.notifyCreateSuccess(result as CardTemplate);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("カードテンプレートの登録ができませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: CardTemplate): CardTemplate {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: CardTemplate): CardTemplate {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }



  /**
   * メッセージカードテンプレート編集ダイアログ（新規追加）を表示します.
   */
  public openCreatePropertyDialog(): void {
    let newCardTempateProp = cardTemplateService.defaultCardTemplateProperty;
    newCardTempateProp.cardTemplate = this.item;
    this.editDialog.open(newCardTempateProp);
  }

  /**
   * メッセージカードテンプレート編集ダイアログを表示します.
   */
  public openEditPropertyDialog(item: CardTemplateProperty): void {
    this.editDialog.open(item);
  }
  /**
   * プレースホルダ編集ダイアログの更新成功時.
   */
  public onUpdatePropertySuccess(updated: CardTemplateProperty): void {
    //一覧にも反映
    const index = this.item.cardTemplateProperties?.findIndex((el) => {
      return el && el.guid === updated.guid;
    });
    if (index && index > -1) {
      this.item.cardTemplateProperties?.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("プレースホルダを更新しました！");
  }

  /**
   * プレースホルダ編集ダイアログの登録成功時.
   */
  public onCreatePropertySuccess(created: CardTemplateProperty): void {
    //一覧にも追加
    this.item.cardTemplateProperties?.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("プレースホルダを登録しました！");
  }

  /**
   * メッセージカードテンプレートプロパティ削除ダイアログを表示します.
   */
  public openDeltePropertyDialog(item: CardTemplateProperty): void {
    this.deleteDialog.open(item);
  }

  /**
   * メッセージカードテンプレートプロパティ削除ダイアログの処理成功時.
   */
  public onDeletePropertySuccess(deleted: CardTemplateProperty): void {
    //一覧からも削除
    const newItems = this.item.cardTemplateProperties?.filter((item) => {
      return item && item?.guid !== deleted.guid;
    });
    this.item.cardTemplateProperties = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("プレースホルダを削除しました！");
  }
}
