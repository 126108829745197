

























































import { Component, Ref, Vue } from "vue-property-decorator";
import { CardTemplate } from "@/graphql/client";
import cardTemplateService from "@/service/cardTemplateService";
import { NotificationState } from "@/store/modules/notification";
import MessageCardTemplateDeleteDialog from "@/components/organisms/message_card/MessageCardTemplateDeleteDialog.vue";
import MessageCardTemplateEditDialog from "@/components/organisms/message_card/MessageCardTemplateEditDialog.vue";

@Component({
  components: {
    MessageCardTemplateDeleteDialog,
    MessageCardTemplateEditDialog,
  },
})
export default class MessageCardTemplateList extends Vue {
  @Ref() readonly editDialog!: MessageCardTemplateEditDialog;
  @Ref() readonly deleteDialog!: MessageCardTemplateDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: CardTemplate[] = [];
  readonly headers = [
    {
      text: "テンプレート名称",
      value: "name",
      width: "25%",
    },
    {
      text: "ジャンル",
      value: "genre",
      formatter: this.formatGenre,
    },
    {
      text: "表示",
      value: "isVisible",
      width: "10%",
      formatter: this.formatIsVisible,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  loadingDataGrid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    cardTemplateService.allCardTemplates().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }
  /**
   * isVisible = true の場合は「表示中」を返します.
   */
  public formatIsVisible(value: boolean): string {
    return value ? "表示中" : "非表示";
  }
  /**
   * ジャンルの名称を返します.
   */
  public formatGenre(value: string): string {
    switch(value) {
      case "1":
        return "出産内祝い";
      case "2":
        return "結婚内祝い・引出物";
      case "4":
        return "その他";
    }
    return "";
  }

  /**
   * メッセージカードテンプレート編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(cardTemplateService.defaultCardTemplate);
  }

  /**
   * メッセージカードテンプレート編集ダイアログを表示します.
   */
  public openEditDialog(item: CardTemplate): void {
    this.editDialog.open(item);
  }

  /**
   * メッセージカードテンプレート編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: CardTemplate): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1);
      this.items.splice(index, 0, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess(
      "メッセージカードテンプレートを更新しました！"
    );
  }

  /**
   * メッセージカードテンプレート編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: CardTemplate): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess(
      "メッセージカードテンプレートを登録しました！"
    );
  }

  /**
   * メッセージカードテンプレート削除ダイアログを表示します.
   */
  public openDeleteDialog(item: CardTemplate): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * メッセージカードテンプレート削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: CardTemplate): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess(
      "メッセージカードテンプレートを削除しました！"
    );
  }
}
