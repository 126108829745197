










import SettingSubPageTemplate from "@/components/templates/SettingSubPageTemplate.vue";
import MessageCardTemplateList from "@/components/organisms/message_card/MessageCardTemplateList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    MessageCardTemplateList,
  },
};
